<template>
  <div class="affiliate-program-page">
    <div class="future container-fluid">
      <div class="container">
        <div class="f-wrapper">
          <div class="content-block">
            <div class="title">
              <span>{{ $t('program_page.title') }}</span>
            </div>
            <div class="description">
              <span>{{ $t('program_page.description') }}</span>
            </div>
            <div class="buttons">
              <button class="aply">{{ $t('program_page.apply') }}</button>
              <button class="manage">{{ $t('program_page.account') }}</button>
            </div>
            <div class="scroll">
              <span>{{ $t('program_page.scroll') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="platform container-fluid">
      <div class="container">
        <div class="p-wrapper">
          <div class="content-top">
            <div class="title">
              <span>{{ $t('program_page.stats') }}</span>
            </div>
            <div class="description">
              <span>
                {{ $t('program_page.descripiton_2') }}
              </span>
            </div>
          </div>
          <div class="content-middle">
            <div class="top">
              <div class="item">
                <div class="title">
                  <span>{{ $t('program_page.join') }}</span>
                </div>
                <div class="description">
                  <span>10,000 {{ $t('program_page.affiliates') }}</span>
                </div>
              </div>
              <div class="item">
                <div class="title">
                  <span>{{ $t('program_page.join') }}</span>
                </div>
                <div class="description">
                  <span>10,000 {{ $t('program_page.affiliates') }}</span>
                </div>
              </div>
              <div class="item">
                <div class="title">
                  <span>{{ $t('program_page.join') }}</span>
                </div>
                <div class="description">
                  <span>10,000 {{ $t('program_page.affiliates') }}</span>
                </div>
              </div>
              <div class="item">
                <div class="title">
                  <span>{{ $t('program_page.join') }}</span>
                </div>
                <div class="description">
                  <span>10,000 {{ $t('program_page.affiliates') }}</span>
                </div>
              </div>
            </div>
            <div class="top second-section">
              <div class="item">
                <div class="title">
                  <span>{{ $t('program_page.join') }}</span>
                </div>
                <div class="description">
                  <span>10,000 {{ $t('program_page.affiliates') }}</span>
                </div>
              </div>
              <div class="item">
                <div class="title">
                  <span>{{ $t('program_page.join') }}</span>
                </div>
                <div class="description">
                  <span>10,000 {{ $t('program_page.affiliates') }}</span>
                </div>
              </div>
              <div class="item">
                <div class="title">
                  <span>{{ $t('program_page.join') }}</span>
                </div>
                <div class="description">
                  <span>10,000 {{ $t('program_page.affiliates') }}</span>
                </div>
              </div>
              <div class="item">
                <div class="title">
                  <span>{{ $t('program_page.join') }}</span>
                </div>
                <div class="description">
                  <span>10,000 {{ $t('program_page.affiliates') }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="content-bottom">
            <div class="button">
              <button class="manage">{{ $t('program_page.manage') }}</button>
            </div>
            <div class="text">
              <span>{{ $t('program_page.text') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="partner container-fluid">
      <div class="container">
        <div class="p-wrapper">
          <div class="content-top">
            <div class="title">
              <span>{{ $t('program_page.partner') }}</span>
            </div>
            <div class="description">
              <span>{{ $t('program_page.partner_description') }}</span>
            </div>
          </div>
          <div class="content-middle">
            <div class="row">
              <div class="col-md-4 item">
                <div class="img">
                  <img src="./img/started.svg" alt="start" />
                </div>
                <div class="title">
                  <span>{{ $t('program_page.start') }}</span>
                </div>
                <div class="description">
                  <span>{{ $t('program_page.description_3') }}</span>
                </div>
              </div>
              <div class="col-md-4 item">
                <div class="img">
                  <img src="./img/started.svg" alt="start" />
                </div>
                <div class="title">
                  <span>{{ $t('program_page.start') }}</span>
                </div>
                <div class="description">
                  <span>{{ $t('program_page.description_3') }}</span>
                </div>
              </div>
              <div class="col-md-4 item">
                <div class="img">
                  <img src="./img/started.svg" alt="start" />
                </div>
                <div class="title">
                  <span>{{ $t('program_page.start') }}</span>
                </div>
                <div class="description">
                  <span>{{ $t('program_page.description_3') }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="content-bottom">
            <div class="buttons">
              <button class="became">{{ $t('program_page.became') }}</button>
              <button class="apply">{{ $t('program_page.apply') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="empower container-fluid">
      <div class="content-box">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-sm-6 empty"></div>
            <div class="col-md-6 col-sm-6 full">
              <div class="empower-box item">
                <div class="title">
                  <span>{{ $t('program_page.empower') }}</span>
                </div>
                <div class="description">
                  <span class="blue">{{ $t('program_page.ern') }}</span>
                </div>
              </div>
              <div class="benefit item">
                <div class="title">
                  <span class="yellow">{{ $t('program_page.payout') }}</span>
                </div>
                <div class="description">
                  <span class="blue">{{ $t('program_page.ern_minimum') }}</span>
                </div>
              </div>
              <div class="content item">
                <div class="title">
                  <span class="yellow">Free Content</span>
                </div>
                <div class="description">
                  <span class="blue">
                    {{ $t('program_page.save_time') }}
                  </span>
                </div>
              </div>
              <div class="hear item">
                <div class="title">
                  <span class="yellow">{{ $t('program_page.first') }}</span>
                </div>
                <div class="description">
                  <span class="blue">{{ $t('program_page.be') }}</span>
                </div>
              </div>
              <div class="support item">
                <div class="title">
                  <span class="yellow">{{ $t('program_page.support') }}</span>
                </div>
                <div class="description">
                  <span class="blue">{{
                    $t('program_page.description_4')
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cards container-fluid">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-sm-4 cards_accept">
            <div class="cards__title">
              <span>{{ $t('program_page.accept') }}</span>
            </div>
          </div>
          <div class="col-md-8 col-sm-8 cards__list">
            <div class="skrill">
              <img src="~@assets/img/skrill.png" alt="skrill" />
            </div>
            <div class="visa">
              <img src="~@assets/img/visa.png" alt="visa" />
            </div>
            <div class="mc">
              <img src="~@assets/img/mc.png" alt="master_card" />
            </div>
            <div class="neteller">
              <img src="~@assets/img/neteller.png" alt="neteller" />
            </div>
            <div class="wire">
              <img src="~@assets/img/wire.png" alt="wire" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" src="./styles.scss"></style>
